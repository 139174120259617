.dashboard-tile {
  display: flex;
  margin-bottom: 60px;
  .card {
    width: 250px;
    border-radius: 10px;
    transform: scale(1.2);
    z-index: 1;
    flex-shrink: 0;
  }
  .card-body {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
  .list-group-item {
    display: flex;
    align-items: center;
    padding-left: 50px;
    flex-grow: 1;
  }
  &.disabled {
    opacity: 0.7;
    filter: saturate(0.5);
  }
}

.dashboard-tile--info {
  .card {
    background: $coworkr-blue-light;
    color: #f0f0f0;
  }
  .list-group-item {
    border-color: $coworkr-blue-light;
  }
}
.dashboard-tile--ocker {
  .card {
    background: $coworkr-ocker-medium;
    // color: #f0f0f0;
    color: #333;
  }
  .list-group-item {
    border-color: $coworkr-ocker-medium;
  }
}
.dashboard-tile--yellow {
  .card {
    background: $coworkr-ocker-light;
  }
  .list-group-item {
    border-color: $coworkr-ocker-light;
  }
}
.dashboard-tile--grey {
  .card {
    background: $coworkr-blue-dark;
    color: white;
  }
  .list-group-item {
    border-color: $coworkr-blue-dark;
  }
}

