.coworkr-green {
  color: $coworkr-green;
}
.coworkr-light {
  color: $coworkr-light;
}
.bg-coworkr-light {
  background-color: $coworkr-light;
}
.navbar-background-color {
  background-color: $navbar-background-color;
}
.coworkr-grey-dark {
  color: $coworkr-grey-dark;
}
.bg-coworkr-grey-dark {
  background-color: $coworkr-grey-dark;
}

.card.panel-coworkr-grey {
  border: 2px solid $coworkr-grey-dark;
  border-radius: 6px;
  > .card-header {
    border-bottom: 4px solid $coworkr-light;
    background-color: $coworkr-grey-dark;
    color: $coworkr-light;
    a {
      color: $coworkr-light;
    }
  }
}

.card.panel-coworkr-grey-switched {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;

  > .card-header {
    border-bottom: 2px solid $coworkr-light;
    color: $coworkr-grey-dark;
    background-color: $coworkr-light;

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $coworkr-dark;
      border-color: darken($coworkr-dark, 10%);
    }
    .custom-control-label::after {
      top: 0;
      left: -2rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    .custom-control-label::before {
      top: 0;
      left: -2rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}


body {
  font-size: 15px;
  background-color: white;
}

nav {
  font-size: 13px;
}

main {
  background-color: white;
}

.display-4 {
  color: $coworkr-dark;
}
.display-3 {
  color: $coworkr-lila;
}

.navbar-campaign-info {
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 0px;
  background-color: $coworkr-grey-dark;
  padding: 15px;
  color: $coworkr-light;
  font-size: 15px;
  border-left: 0;
  border-right: 0;
  background-color: $coworkr-grey-dark;
  background-image: url("./images/black-paper.png");
}

.campaign-info-hr {
  height: 4px;
  margin: 0px 0px 10px 0px;
  border: none;
  box-shadow: 0px 0px 5px 1px #e0a000;
}

.panel-coworkr-grey {
  .btn-group-vertical {
    .btn {
      text-align: left;
    }
  }
}

.nav-tabs-coworkr {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .btn {
    margin: 5px;
  }

  background-color: $coworkr-grey-dark;
  border-bottom: 2px solid $coworkr-light;

  li {
    transition: all ease 0.7;

    a {
      transition: all ease 0.7;
      outline: none;
      color: $coworkr-light;
      border-radius: 0px;
      box-shadow: none;

      &:hover, &:focus {
        box-shadow: inset 0 -2px 0 $coworkr-light;
        background-color: $coworkr-light;
        color: $coworkr-grey-dark;
      }

      &:focus:hover {
        color: $coworkr-grey-dark;
        box-shadow: inset 0 -2px 0 $coworkr-light;
      }
    }
  }

  li.active {
    a {
      box-shadow: inset 0 -2px 0 $coworkr-light;
      background-color: $coworkr-light;
      color: $coworkr-grey-dark;

      &:hover, &:focus {
        box-shadow: inset 0 -2px 0 $coworkr-light;
        background-color: $coworkr-light;
        color: $coworkr-grey-dark;
      }

      &:focus:hover {
        box-shadow: inset 0 -2px 0 $coworkr-light;
        color: $coworkr-grey-dark;
      }
    }
  }
}

.nav-pills-coworkr {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background-color: $coworkr-grey-dark;
  border-bottom: 2px solid $coworkr-light;

  .nav-item {
    .nav-link {
      color: $coworkr-light;
      border-radius: 0px;

      &:hover, &:focus {
        background-color: $coworkr-light;
        color: $coworkr-grey-dark;
      }
    }
  }

  .nav-link.active {
    background-color: $coworkr-light;
    color: $coworkr-grey-dark;

    &:hover, &:focus {
      background-color: $coworkr-light;
      color: $coworkr-grey-dark;
    }
  }
}

#coworkr-footer {
  padding-top: 30px;
  background-color: $coworkr-grey-dark;
  color: $coworkr-light;

  .footer-links {
    font-size: 13px;
  }

  h4 {
    color: $coworkr-light;
  }

  hr {
    border-color: $coworkr-light;
  }

  ul {
    padding-left: 0px;
  }

  li {
    list-style: none;
    margin-top: 0px;

    a {
      font-size: 15px;
      color: $coworkr-light;

      &:hover, &:focus {
        color: darken($coworkr-light, 10);
        text-decoration: none;
      }

      display: inline-block;
      margin-left: 10px;
    }
  }

  .footer-phone {
    margin-left: 10px;
  }

  .copyright {
    padding-top: 40px;
    display: inline-block;
    vertical-align: bottom;
    -moz-hyphens: auto;
    hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
  }
}

.campaign-info-logo {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border: 4px solid $coworkr-light;
}

.breadcrumb {
  li {
    a {
      color: $coworkr-light;

      &:hover, &:focus {
        color: darken($coworkr-light, 10);
      }
    }
  }
}

a.list-group-a {
  margin-top: 2px;
  margin-bottom: 2px;
  display: block;
  text-decoration: none;

  span {
    text-decoration: none;
  }

  &:hover, &:focus {
    outline: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
}

// old

.job-shares {
  margin-top: 5px;
  font-size: 90%;
  color: #555;

  .fa-li {
    top: 4px;
  }

  .fa-mouse-pointer {
    margin-left: 4px;
  }
}

.background-coworkr-grey {
  background-color: #1a1a1a;
}

.panel-coworkr-login {
  border-color: darken(#2c2c2c, 10);
  background-color: #2c2c2c;

  .card-header {
    border-color: darken(#2c2c2c, 10);
    background-color: #575757;

    h3 {
      color: #75D921;
    }
  }
}

#coworkr-features {
  padding-top: 20px;
  padding-bottom: 60px;

  p {
    color: #2c2c2c;
    font-size: 18px;
  }
}

#coworkr-ueber {
  padding-top: 60px;
  padding-bottom: 60px;

  p {
    hyphens: auto;
    text-align: justify;
    color: #ececec;
    font-size: 18px;
  }
}

#coworkr-login {
  background-color: #ddd;
  padding-top: 60px;
  padding-bottom: 60px;

  p {
    hyphens: auto;
    text-align: justify;

    //color: #C3C1C1
    font-size: 18px;
  }
}

.coworkr-logo {
  width: 80%;
}

#coworkr-registrieren {
  padding-top: 60px;
  padding-bottom: 60px;

  .btn-primary-coworkr {
    margin-top: 20px;
    font-size: 20px;
    background-color: #206c80;
    border-color: darken(#206c80, 10);

    &:hover, &:focus {
      background-color: darken(#206c80, 10);
      border-color: darken(#206c80, 12);
    }
  }

  p {
    color: #C3C1C1;
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .coworkr-logo {
    width: 600px;
  }
}

.account-box {
  .fa-warning {
    color: #dd2222;
  }

  .fa-2x {
    position: relative;
    top: 8px;
    margin-right: 15px;
    margin-left: 10px;
  }

  .account-image {
    margin-right: 10px;
    padding: 3px;
    background: white;
    width: 56px;
    text-align: center;
  }

  .account-image, .account-name {
    display: inline-block;
    vertical-align: middle;
  }
}

.organisation-sidebar {
  max-width: 220px;

  .fa-share-alt, .fa-mouse-pointer {
    color: #666;
  }
}

.navbar-toggle {
  line-height: inherit;
  padding: 9px 10px;
}

.relative {
  position: relative;
}

.navbar-coworkr {
  li {
    margin-bottom: 0;
  }
}
.navbar-light {
  box-shadow: 0 1px 2px rgba(0,0,0,0.3)
}

.label.label-jp-profile {
  display: inline-block;
  margin-right: 0.5em;
  text-align: right;
  min-height: 48px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

  img {
    max-height: 42px;
  }
}

.line-height-1 {
  line-height: 1;
}

.badge.badge-jp-profile {
  display: inline-block;
  margin-right: .5em;
  text-align: right;
  min-height: 48px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  img {
    max-height: 42px;
  }
}
.card-header h3 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.big-faded-icon {
  position: absolute;
  left: 0;
  font-size: 30px !important;
  bottom: 8px;
}

.bigger-faded-icon {
  position: absolute;
  left: 0;
  font-size: 80px !important;
}

.card-header .card-title {
  margin-bottom: 0;
}

.btn-sticky-save {
  position: sticky;
  bottom: 10px;
  &.btn, .btn {
    margin-left: 20px;
    box-shadow: 0 0 3px #999;
  }
}
