$providers:
"x-twitter" #000000 white rgba(0, 0, 0, 0.1),
"facebook" #3b5998 white rgba(59, 89, 152, 0.1),
"linkedin" #0077b5 white rgba(0, 119, 181, 0.1),
"xing" #026466 #cfdc00 rgba(2, 100, 102, 0.1),
"whatsapp" #25d366 white rgba(2, 100, 102, 0.1),
"telegram" #24a2e0 white rgba(2, 100, 102, 0.1),
"facebook-messenger" #1B74E4 white rgba(59, 89, 152, 0.1),
"native" #FF9900 white rgba(25, 25, 25, 0.1);

@each $provider, $background, $text, $transparent in $providers {
  .provider-#{$provider} {
    background-color: $background !important;
    color: $text;
    a {
      color: #eee !important;
    }
  }
  .panel-#{$provider} {
    .card-header, .modal-header {
      background-color: $background !important;
      color: $text !important;
    }
    .btn-primary {
      background-color: $background !important;
      color: $text !important;
      border: $background !important;
    }
    &.modal-content {
      border-color: $background !important;
      border-width: 3px;
      border-radius: 7px;
    }

    .btn-primary:hover {
      opacity: 0.8 !important;
    }
  }

  // Foundation E-Mail
  table.button.btn-#{$provider} table a,
  .btn-#{$provider} {
    border-radius: 3px;
    background-color: $background;
    color: $text;
    border-color: darken($background, 5%);
    &:focus,
    &:hover {
      background-color: darken($background, 10%);
      color: darken($text, 10%) !important;
    }
  }
  table.button.btn-#{$provider} table td {
    background-color: $background;
    border-color: 0px solid darken($background, 5%);
  }
  table.button.btn-#{$provider}:hover table td {
    background-color: darken($background, 10%);
  }
  table.button.btn-#{$provider}:hover table a {
    border: 0px solid darken($background, 10%);
  }

  .background-color-#{$provider} {
    background-color: $background;
  }
  .text-color-#{$provider} {
    color: $background;
  }
  .text-color-transparent-#{$provider} {
    color: $transparent;
  }

  .page-#{$provider} {
    a:not(.btn-#{$provider}) {
      color: $background !important;
    }
    a:not(.btn-#{$provider}):hover,
    a:not(.btn-#{$provider}):focus {
      color: darken($background, 10%) !important;
    }
    a.no-page-color {
      color: inherit !important;
    }
    a.no-page-color:hover,
    a.no-page-color:focus {
      color: inherit !important;
    }
  }
}



