@import "./variables";

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.fw-300 {
  font-weight: 300;
}

.p-t {
  padding-top: 15px;
}

.p-t-2x {
  padding-top: 30px;
}

.m-t-2x {
  margin-top: 30px;
}

.bs-top {
  box-shadow: 0 -5px 5px -5px #2f4957;
}

.fs-18 {
  font-size: 18px;
}

.fs-12 {
  font-size: 12px;
}

.main-head {
  margin-top: -30px;
  min-height: 300px;
  color: #eee;

  h1, h2, h3, h4 {
    color: #eee;
  }
}

.text-border-white {
  text-shadow: (-1px) -1px 1px white, 1px -1px 1px white, -1px 1px 1px white, 1px 1px 1px white;
}

.hyphens {
  hyphens: auto;
}

.justify {
  text-align: justify;
}

.panel-image {
  img {
    width: 100%;
  }
}

.divider {
  height: 2px;
  width: 100%;
  display: block;
  margin: 0px;
  overflow: hidden;
  background-color: $coworkr-light;
}

.divider + .list-group-item {
  border-top-width: 0px !important;
}

.list-group-item.list-group-item-before-divider {
  border-bottom-width: 0px !important;
}

//.list-group-item:has(+ .divider)
//  border-bottom-width: 0px !important
.panel-heading + .panel {
  margin-top: 15px;
}

.panel.panel-with-panel {
  box-shadow: none;
  background-color: inherit;

  .panel-heading {
    border-radius: 2px;
  }
}

.alert.alert-info {
  span.alert-info-heading {
    font-weight: bold;
    font-size: 2rem;
  }
}
