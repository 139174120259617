.page-header {
  padding-top: 50px;
  padding-bottom: 50px;
  .breadcrumb {
    border: none;
		background: none;
		margin-left: 0;
		padding: 0 0 0 5px;
  }
  .breadcrumb-item a {
    color: $primary !important;
  }
}

.page-title {
  @extend .display-1;
  color: $eb-blue;
}
.page-section-title {
  @extend .display-2;
  color: $eb-blue;
}
.page-sub-title {
  @extend .display-4;
  color: $eb-blue;
}
.page-floating-card {
  position: absolute;
  right: 30px;
  top: 0;
  background-color: white;
  padding: 15px;
  border-style: solid;
  border-width: 1px 4px 5px 1px;
  border-radius: 0.3rem;
  border-color: darken($page-body-border-color, 15%);
  z-index: 1;
  @media only screen and (max-width: 900px) {
    position: static !important;
  }
}
.page-body {
  border-top: 3px solid $page-body-border-color;
  background-color: $page-body-background-color;
  padding-top: 50px;
  padding-bottom: 30px;
}
body.has-page-card .page-title {
  margin-right: 300px;
}
.share_row_holder {
  margin-top: -50px;
}

@media screen and (max-width: 900px) {
  h1.page-title {
    font-size: 2.0rem !important;
  }
  h2.page-sub-title {
    font-size: 1.5rem !important;
  }
  .page-header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

