.panel {
  @extend .card;
  @extend .mb-4;
}
.panel-heading {
  @extend .card-header;
}
.panel-title {
  @extend .card-title;
  @extend .mb-0;
}
.panel-footer {
  @extend .card-footer;
}
.panel-body {
  @extend .card-body;
}
.btn-default {
  @extend .btn-secondary;
}
.pull-right {
  @extend .float-right;
}
.collapse.in {
  display: block;
}
.btn-xs {
  @extend .btn-sm;
}
.col-xs-12 {
  @extend .col-sm-12;
}
.label {
  @extend .badge;
}
.label-default {
  @extend .badge-dark;
}

.hide {
  display: none;
}

img.grayscale {
  filter: grayscale(100%);
  transition: 0.2s all ease-in-out;
  &:hover {
    filter: grayscale(0%);
  }
}

.bs3-modal {
  &.in {
    opacity: 1.0 !important;
  }
  &.in .modal-dialog {
    transform: none;
  }
}
.modal-backdrop.in {
  opacity: 0.5 !important;
}

.img-responsive {
  @extend .img-fluid;
}
