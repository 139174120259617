$coworkr-light: #E0A000;
$coworkr-dark: #875801;
$coworkr-green: #6C8020;
$coworkr-grey-light: #b3b3b3;
$coworkr-grey-medium: #808080;
$coworkr-grey-dark: #2c2c2c;
$coworkr-lila: #9C27B0;
$navbar-background-color: #f6f6f6;
$grid-float-breakpoint: 1199px;

// Schaltfläche Schritt 1 #16B6E0 (helleres blau)
$coworkr-blue-light: #16B6E0;
// Schaltfläche Schritt 2a #E0A000 (Standard Cowork Ocker)
$coworkr-ocker-medium: #E0A000;
// Schaltfläche Schritt 2b #FFBE19 (helleres Ocker)
$coworkr-ocker-light: #ffbe19;
// Schaltfläche Schritt 3 #0F7894 (dunkleres blau)
$coworkr-blue-dark: #0F7894;

$eb-blue: $coworkr-dark;
$page-body-background-color: #f2f2f2;
$page-body-border-color: darken(#f2f2f2, 5%);

$blue:    #2196F3 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e51c23 !default;
$orange:  #fd7e14 !default;
$yellow:  #ff9800 !default;
$green:   #4CAF50 !default;
$teal:    #20c997 !default;
$cyan:    #9C27B0 !default;
// $primary:       $coworkr_lila !default;
$primary:       $coworkr-blue-dark !default;
$link-color: $blue;
$success:       $green !default;
$info:          $coworkr-lila !default;
$warning:       $yellow !default;
$danger:        $red !default;
$info: $eb-blue;


$display1-size: 3.2rem;
$display2-size: 2.7rem;
$display3-size: 2.3rem;
$display4-size: 2.0rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
@import "~bootswatch/dist/lumen/_variables";
