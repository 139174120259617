.chip {
  border: 1px solid #ccc;
  height: auto;
  min-height: 26.5px;
  padding: 0 .1rem 0 .4rem;
  align-items: center;
  background: #f0f1f4;
  border-radius: 5rem;
  font-size: 90%;
  color: #667189;
  line-height: .8rem;
  margin: .1rem;
  max-width: 100%;
  text-decoration: none;
  display: inline-flex;

  .avatar {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    margin-left: -7px;
    margin-right: 3px;
  }
  span:first-child {
    margin-right: 5px;
  }
  .chip__value {
    margin-left: 3px;
    background: white;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    height: 1.44rem;
    display: inline-flex;
    line-height: 1rem;
    align-items: center;
    padding: 2px 5px 2px 5px;
    margin-right: 0 !important;
  }
  .btn {
    padding: 2px 5px;
    font-size: 80%;
    margin: 0 0 0 3px;
    border-color: #999;
    border-radius: 0;
  }

  .btn-clear {
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    padding: 2px 15px 2px 5px;
    margin: 0;
    height: 1.44rem;
    transition: background-color 0.3s ease-in-out;
    align-items: center;
    display: inline-flex;
    background: 0 0;
    border: 0;
    color: currentColor;
    line-height: 1rem;
    opacity: 1;
    text-decoration: none;
    width: 1rem;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    font-size: .8rem;
    &::before {
      position: relative;
      bottom: 1px;
      content:"\2715"
    }
    &:hover {
      background-color: #999;
      color: white;
      opacity:.95;
    }
  }
}

.identity-chip-list {
  display: flex;
  flex-wrap: wrap;

}
