@import './styles/variables';
// kein webfont von Google laden!
$web-font-path: "./styles/_font.css";
@import './styles/font';

@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/lumen/_bootswatch";
@import "~bootstrap-vue/src/index";

@import './styles/fontawesome';
@import './styles/page.scss';


@import './styles/global';
@import './styles/provider_styles';
@import './styles/utilities';
@import './styles/bs3_shim';
@import './styles/chip';

@import '../../app/components/design/dashboard_tile';

.card + .card {
  margin-top: 5px;
}

.page-body {
  min-height: 60vh;
}

.ProseMirror {
  @extend .form-control;
  height: auto;
}
